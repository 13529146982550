import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import HelmetComponent from '../components/helmetcomponent';
import Layout from '../components/layout';
import NotifModal from '../components/notifmodal';
import SubscribeModal from '../components/subscribemodal';
import ArrowRight from '../img/arrow-right.svg';

export default function Template({ data }) {
  const {
    title,
    bannerImageThumbnail,
    bannerCaption,
    bannerReportButtonText,
    bannerReportButtonLink,
    donateSectionImg,
    donateSectionHeading,
    donateSectionDesc,
    donateSectionButtonText,
    donateSectionButtonLink,
    oneTimeDonationImg,
    oneTimeDonationHeading,
    oneTimeDonationDesc,
    oneTimeDonationButtonText,
    inviteChurchSectionImage,
    inviteChurchHeading,
    inviteChurchDesc,
    inviteChurchDesc2,
    inviteChurchButtonText,
    inviteChurchButtonLink,
    newsletterSectionImg,
    newsletterSectionHeading,
    newsletterSectionDesc,
    newsletterSectionButtonText,
  } = data.allContentfulPageEvents.edges[0].node;

  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [email, setEmail] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [notifModalShow, setNotifModalShow] = useState(false);

  const handleChangeFirstname = e => {
    setFirstname(e.target.value);
  };

  const handleChangeLastname = e => {
    setLastname(e.target.value);
  };

  const handleChangeEmail = e => {
    setEmail(e.target.value);
  };

  // This handles subscription to newsletter when clicking submit
  const handleSubmit = async event => {
    event.preventDefault();
    await addToMailchimp(email, { FNAME: firstname || '', LNAME: lastname || '' });
    setModalShow(false);
    setNotifModalShow(true);
  };

  return (
    <Layout nav="colored-nav">
      <HelmetComponent title={title} />
      <section className="gt23-banner">
        <img src={bannerImageThumbnail.file.url} id="thumbnail" />
        <div className="hero-banner-caption">
          <h2>{bannerCaption}</h2>
          <div className="d-flex justify-content-center">
            <a
              className="cursor-pointer btn-reg btn-purple p-3 mt-5 w-100 whitespace-nowrap"
              href={bannerReportButtonLink}
              target="_blank"
            >
              {bannerReportButtonText}
            </a>
          </div>
        </div>
      </section>
      <section className="two-columns" id="section-donate">
        <div className="events-container">
          <div className="two-columns-content row">
            <div className="col-md-6 events-photo">
              <Img alt="Donate now" className="w-100" fluid={donateSectionImg.fluid} />
            </div>
            <div className="events-text">
              <h2>{donateSectionHeading}</h2>
              <p>{donateSectionDesc.donateSectionDesc}</p>
              <div className="d-flex justify-content-center">
                <a
                  className="cursor-pointer btn-reg btn-purple p-3 mt-5 w-100"
                  href={donateSectionButtonLink}
                  target="_blank"
                >
                  {donateSectionButtonText}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="two-columns" id="section-sponsor-a-mom">
        <div className="events-container">
          <div className="two-columns-content row flex-row-reverse">
            <div className="col-md-6 events-photo">
              <Img alt="Sponsor a mom" className="w-100" fluid={oneTimeDonationImg.fluid} />
            </div>
            <div className="events-text">
              <h2>{oneTimeDonationHeading}</h2>
              <p>{oneTimeDonationDesc.oneTimeDonationDesc}</p>
              <div className="d-flex justify-content-center">
                <a className="btn-reg btn-purple p-3 mt-5 w-100" data-formid="2940" href="#">
                  {oneTimeDonationButtonText}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="two-columns" id="section-sponsor-a-mom">
        <div className="events-container">
          <div className="two-columns-content row">
            <div className="col-md-6 events-photo">
              <Img alt="Sponsor a mom" className="w-100" fluid={inviteChurchSectionImage.fluid} />
            </div>
            <div className="events-text">
              <h2>{inviteChurchHeading}</h2>
              <p className="invite-church">{inviteChurchDesc}</p>
              <p className="invite-church">{inviteChurchDesc2}</p>
              <div className="d-flex justify-content-center">
                <a
                  className="btn-reg btn-purple p-3 mt-5 w-100"
                  href={inviteChurchButtonLink}
                  target="_blank"
                >
                  {inviteChurchButtonText}
                  <img alt="arrow-right" className="pl-3" src={ArrowRight} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="two-columns" id="section-sponsor-a-mom">
        <div className="events-container">
          <div className="two-columns-content row  flex-row-reverse">
            <div className="col-md-6 events-photo">
              <Img fluid={newsletterSectionImg.fluid} alt="newsletter-logo" />
            </div>
            <div className="events-text">
              <h2>{newsletterSectionHeading}</h2>
              <p>{newsletterSectionDesc.newsletterSectionDesc}</p>
              <div className="d-flex justify-content-center">
                <a
                  onClick={() => setModalShow(true)}
                  className="btn-reg btn-purple p-3 mt-5 cursor-pointer w-100"
                >
                  {newsletterSectionButtonText}
                  <img alt="arrow-right" className="pl-3" src={ArrowRight} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SubscribeModal
        modalShow={modalShow}
        onHide={() => setModalShow(false)}
        heading={data.allContentfulNewsletterModal.edges[0].node.heading}
        subheading={data.allContentfulNewsletterModal.edges[0].node.subheading.subheading}
        handleSubmit={handleSubmit}
        handleChangeFirstname={handleChangeFirstname}
        handleChangeLastname={handleChangeLastname}
        handleChangeEmail={handleChangeEmail}
      />
      <NotifModal
        modalShow={notifModalShow}
        onHide={() => setNotifModalShow(false)}
        title={data.allContentfulNewsletterModal.edges[0].node.successModalHeading}
        body={
          data.allContentfulNewsletterModal.edges[0].node.successModalBodyText.successModalBodyText
        }
      />
    </Layout>
  );
}

export const eventsQuery = graphql`
  query Events($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        description
      }
    }
    allContentfulPageEvents {
      edges {
        node {
          title
          bannerImageThumbnail {
            file {
              url
            }
          }
          bannerCaption
          bannerReportButtonText
          bannerReportButtonLink
          donateSectionImg {
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid
            }
          }
          donateSectionHeading
          donateSectionDesc {
            donateSectionDesc
          }
          donateSectionButtonText
          donateSectionButtonLink
          oneTimeDonationImg {
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid
            }
          }
          oneTimeDonationHeading
          oneTimeDonationDesc {
            oneTimeDonationDesc
          }
          oneTimeDonationButtonText
          inviteChurchSectionImage {
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid
            }
          }
          inviteChurchHeading
          inviteChurchDesc
          inviteChurchDesc2
          inviteChurchButtonText
          inviteChurchButtonLink
          newsletterSectionImg {
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid
            }
          }
          newsletterSectionHeading
          newsletterSectionDesc {
            newsletterSectionDesc
          }
          newsletterSectionButtonText
        }
      }
    }
    allContentfulNewsletterModal {
      edges {
        node {
          heading
          subheading {
            subheading
          }
          successModalBodyText {
            successModalBodyText
          }
          successModalHeading
        }
      }
    }
  }
`;
